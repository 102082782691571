<template>
  Refreshing in {{ timer }}
  <div>
    <div class="dashboard">
      <div class="sidebar">
        <ConversationList/>
      </div>
      <div class="content-container">
        <Conversation/>
      </div>
    </div>
  </div>
</template>
<script>
import ConversationList from '../components/ConversationList.vue';
import Conversation from '../components/Conversation.vue';

export default {
  data() {
    return {
      error: '',
      timer: 10
    };
  },
  methods: {},
  components: {
    ConversationList,
    Conversation,
  },
  watch: {
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timer--;
          }, 1000);
        } else {
          location.reload();
        }
      },
      immediate: true
    }

  }
};
</script>
